import React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import RozvrhPdf from "../../static/stk-praha-rozvrh.pdf"

const RozvrhTanecnichSalu = () => (

	<Layout>
		<Seo title="Rozvrh tanečních sálů" description="Jestliže k nám začnete chodit už se tance nikdy nezbavíte! :)" />

		<div className="stk-background stk-background--onas"></div>

		<div className="stk-container stk-container--top-margin">
			<h1 className="stk-h1">Rozvrh tanečních sálů STK&nbsp;Praha</h1>

			<h2 className="stk-h2 mt-5 mb-2">Taneční sál Casablanca - Praha&nbsp;3 - Vinohrady</h2>
			<a className="stk-button stk-button--small stk-button--homepage mb-3" target="_blank" rel="noreferrer" href={RozvrhPdf}>
				Aktuální rozvrh
			</a>

			<h2 className="stk-h2 mt-5 mb-2">Taneční sál Bohemia&nbsp;Dance - Praha&nbsp;7 - Holešovice</h2>
			<a className="stk-button stk-button--small stk-button--homepage mb-3" target="_blank" rel="noreferrer" href="http://bohemiadance.cz/rozvrh">
				Aktuální rozvrh
			</a>

			<h2 className="stk-h2 mt-5 mb-2">Taneční sál Místo&nbsp;tance - Praha&nbsp;6 - Petřiny</h2>
			<a className="stk-button stk-button--small stk-button--homepage mb-5" target="_blank" rel="noreferrer" href={RozvrhPdf}>
				Aktuální rozvrh
			</a>

		</div>

	</Layout>

)

export default RozvrhTanecnichSalu